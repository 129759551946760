<template>
  <div>
    <MyHeader :title="$t('lang.menu_statistics')" />
    <van-grid :column-num="2" :border="false" icon-size="48px">
      <van-grid-item
        icon="/img/total1.png"
        is-link
        :to="{ name: 'Pass', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_passrate')"
      ></van-grid-item>
      <van-grid-item
        icon="/img/total2.png"
        is-link
        :to="{ name: 'Unusual', params: { p: { pagesize: 1 } } }"
        :text="$t('lang.menu_abnormal')"
      ></van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import MyHeader from "@/components/Header.vue";
export default {
  components: { MyHeader },
};
</script>
